<template>
  <GlassContainer width="40%"><h1>securly.com.webcr.top</h1>
  currently being worked on, forgive me if things are broken<br>
  also, I'm reading my emails for the first time in a while, send me things!</GlassContainer><br>
  <hr>
  <div class="content" width="100%">
     
    <GlassContainer id="dropdowns">
      <ClickableGlass title="DragMark (if javascript is blocked)" expandable="true" width="90%">
        1. create a bookmark to a link that's blocked (like <a href="securly.com.webcr.top">securly.com.webcr.top</a>) by just editing any bookmark and changing the URL to something<br>
        2. on a non-blocked page, drag the bookmark into the center of the screen<br>
        3. PROFIT!!!<br>
        If you arent using securly and this works for you contact me using the form/email below so i can label this accordingly
      </ClickableGlass>
      <ClickableGlass title="Cookie Corruption (securly)" expandable="true" width="90%">
        1. create a bookmark for any page, and right click on it and select edit.<br>
        2. paste the text below into the "url" box<br>
        <input type="text" readonly value="javascript:document.cookie=&#039;crextn_clear_cache_at=:;expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/&#039;;"><br>
        3. when you are on the securly blocked screen, click the bookmark<br>
        If it does nothing and websites are still blocked, your school has javascript URLs disabled. thats too bad :/ let me know at the contact section so i can get a sense of how many people this effects.
        
      </ClickableGlass>
     
     
      
      <ClickableGlass title="omadadns (all filter extensions)" expandable="true" width="90%">
        https://docs.titaniumnetwork.org/kajigs/omadadns/
      </ClickableGlass>
      <ClickableGlass title="Funny little js thingies!" expandable="true" width="90%">
        to use these you gotta make a bookmark, edit it and paste the thing below as the url, then just click the bookmark to activate the thingy<br>
        <ul>
          <h3>website editor</h3>
          <li><input type="text" readonly value="javascript:document.body.contentEditable = true;"></li>
          <h3>the hydraulic press</h3>
          <li><input type="text" readonly value="javascript:(function(){var all=document.getElementsByTagName(&quot;*&quot;);var depth=window.prompt(&quot;What depth should the crushing happen at? [leave empty for default] (how many nested elements does an element need to be considered ineligible for crushing)&quot;);function depthCheck(number,element){if(element.getAttribute(&quot;crushable&quot;)){return true;}if(element.children.length&gt;0){if(number==0){return false;}for(var childIndex=0;childIndex&lt;element.children.length;childIndex++){var childCheck=depthCheck(number-1,element.children[childIndex]);if(childCheck==false){return false;}}}element.setAttribute(&quot;crushable&quot;,&quot;&quot;);return true;}var accel=0.1;var intervalTime=500;var accelRate=1.05;var minInterval=10;for(var i=0,max=all.length;i&lt;max;i++){if(!depthCheck(depth,all[i])){console.log(&quot;Failed Depth Check...&quot;);continue;}console.log(&quot;Depth Check Succeeded:&quot;);var element=all[i];var f=element.getBoundingClientRect();element.style.top=f[&quot;y&quot;]+&quot;px&quot;;element.style.left=f[&quot;x&quot;]+&quot;px&quot;;element.style.width=f[&quot;width&quot;]+&quot;px&quot;;element.style.height=f[&quot;height&quot;]+&quot;px&quot;;element.style.position=&quot;absolute !important&quot;;(function crushElement(l,el){var crushInterval=setInterval(function(){var speed=accel*3/400000+300;console.log(&quot;Crushing at speed:&quot;,speed);el.style.top=Math.max(el.style.top.slice(0,-2)-speed,0)+&quot;px&quot;;el.style.left=Math.max(el.style.left.slice(0,-2)-speed,0)+&quot;px&quot;;el.style.width=Math.max(el.style.width.slice(0,-2)-speed/20,0)+&quot;px&quot;;el.style.height=Math.max(el.style.height.slice(0,-2)-speed/20,0)+&quot;px&quot;;accel+=0.1;intervalTime=Math.max(intervalTime*accelRate,minInterval);if(el.style.width===&quot;0px&quot;&amp;&amp;el.style.height===&quot;0px&quot;){clearInterval(crushInterval);}},intervalTime);})(i,element);}})();"></li>
          <h3>rainbowifier</h3>
          <li><input type="text" readonly value="javascript:(function(){var speed=prompt(&quot;Enter the animation speed in seconds (default is 5):&quot;,&quot;5&quot;);if(speed===null||speed.trim()===&quot;&quot;||isNaN(speed)){speed=5;}var css=`*:not(img):not(svg){background-image:linear-gradient(270deg,#FF0000,#FF7F00,#FFFF00,#00FF00,#0000FF,#4B0082,#8B00FF);animation:rainbow%20${speed}s%20infinite;background-size:400%%20400%;color:white!important;}@keyframes%20rainbow{0%{background-position:0%%2050%;}50%{background-position:100%%2050%;}100%{background-position:0%%2050%;}}%60;var%20style=document.createElement('style');style.appendChild(document.createTextNode(css));document.head.appendChild(style);})();"></li>
          
          </ul>
      </ClickableGlass>
       <ClickableGlass title="contact me! right here! do it here! contact mE HERE THORUGH HERE" expandable="true" width="90%">
        email (CONTACT ONLY FROM PERSONAL EMAIL AND NOT SCHOOL EMAIL) - striped_dibble_0j@icloud.com<br>
        might be a little till I can respond this email is filled with random junk<br>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLqEHJD8R4O1glfQbgEHi2gXgLwmu2lgD2R0zSn6Lbm8M4-Q/viewform?usp=sf_link">form for contacting if personal email is not availible</a>
      </ClickableGlass>
    </GlassContainer>
    <GlassContainer block="false" width="350px" height="100%" id="faq">
      <h1>FAQ</h1>
      <h3>none of these work???!?!?</h3>
      if truely none of them work for you, contact me through the contact form/email and let me know with details of what extension you have and what you tried.
      <h3>why do I have to keep doing cookie corruption</h3>
      seemingly, the cookies of securly expire after a bit and so youll have to redo the exploit thats why i have you bookmark it now you just gotta click the bookmark to redo it!
    <h3>Why "securly.com.webcr.top"?</h3>
      back in my day having securly.com in your domain name unblockable, that is no longer the case, its now just an artifact of an earlier time
     <h3>working on anything?</h3>
      mayb... chrome://chrome-signin
      <h3>who are you</h3>
      im batman >:3
      <h3><img src="https://i.ibb.co/mtqRdcc/rude-paul.jpg" width="10%">?</h3>
      Yes.
      </GlassContainer>
  </div>
  <a href="https://www.freecounterstat.com" title="website hit counter"><img src="https://counter6.optistats.ovh/private/freecounterstat.php?c=kh4buumncxpqza4twz3zezubdtakx1yl" border="0" title="website hit counter" alt="website hit counter"></a>

</template>

<script>
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "q7qjmmclvo");

import ClickableGlass from './components/ClickableGlass.vue';
import GlassContainer from './components/GlassContainer.vue'
export default {
  name: 'App',
  components: {
    GlassContainer,
    ClickableGlass
  }
}
  </script>

<style>

pre {
  font-family: monospace;
  display: block;
  white-space: pre;
  overflow-x: auto;
  max-width: 100%; /* Ensure it doesn't expand beyond the container */
  word-break: break-word; /* Break overly long words if necessary */
  padding: 8px;
  border-radius: 4px;
}
  
ul {
  text-align: left;
}
  
#dropdowns {
  max-width:100%;
  flex:1;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 25px;
  
}

#faq {
  box-sizing: border-box;
  margin: 25px;
}

.flex {
  display: flex
}
  
.content {
  display: flex;
}
h1 {
  font-size: 50px
}
h2 {}
body {
  overflow-x: hidden;
  margin: 0px !important;
  /*image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
    /*image-rendering: -moz-crisp-edges;          /* Firefox                        */
    /*image-rendering: -o-crisp-edges;            /* Opera                          */
    /*image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    /*image-rendering: pixelated;                 /* Universal support since 2021   */
    /*image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
    /*-ms-interpolation-mode: nearest-neighbor;   /* IE8+                           */

  background-image: url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c3c11a4e-961b-47ec-8f72-acebce4c4562/dgd7lna-ac16f51b-e974-4b8b-b354-66bb9888ad37.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2MzYzExYTRlLTk2MWItNDdlYy04ZjcyLWFjZWJjZTRjNDU2MlwvZGdkN2xuYS1hYzE2ZjUxYi1lOTc0LTRiOGItYjM1NC02NmJiOTg4OGFkMzcucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.ZktKEgi_XeABq25KljzOESRh6vMPb-MIJH0D9xufths');
  background-size: cover;
  height: 100vh;
  padding:0;
}
#app {
   text-shadow: rgb(20, 20, 20) 0px 0 5px;
  -webkit-text-stroke-color: black;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #cfcfcf;
}
a {
  color: rgb(211, 164, 164)
}
</style>
